.transition{
    overflow-y: hidden;
    transition: height 0.5s ease;
    float: left;
    width: 100%;
  }

  .checkbox-div{
    height: 30px;
    width: 100%;
    border-top: 1px white solid;
    color: white;
    background-color: #057DB8;
    font-size: 17px;
    text-align: center;
    line-height: 30px;
    float: left;
  }

  .white-background{
    float: left;
    width: calc(100% - 4px);
    border: 2px #057DB8 solid;
    border-top: 0;
    background-color: white;
    height: fit-content;
  }

  .manage-trial-div{
    float: left;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }

  .trial-label-div{
    font: inherit;
    float: left;
    height: 40px;
    color: rgb(76, 75, 75);
    line-height: 40px;
    margin-left: 20px;
    margin-top: 5px;
    font-size: 22px;
  }

  .loading-parent-manage-trials{
    float: left;
    margin-top: 40px;
    width: 200px;
    height: 200px;
    align-self: center;
  }

  .coords-sites-div{
    float: left;
    width: 100%;
    height: fit-content;
  }
  
  .add-coord-layout{
    width: calc(100% - 40px);
    margin-right: 20px;
    @media all and (min-width: 931px) {
      width: 690px;
      margin-right: calc(100% - 720px);
    }
    margin-left: 20px;
    margin-top: 20px;
    border-color: black;
    border-style: solid;
    border-radius: 5px;
    height: fit-content;
    float: left;
    background-color:#057cb822;
  }
  
  .add-coord-sites-div{
    overflow-y: scroll;
    max-height: 200px;
    background-color: white;
    height: fit-content;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
    float: left;
  }
  
  .add-coord-button{
    float: left;
    border-radius: 5px;
    background-color: #046a9d;
    font: inherit;
    color: white;
    font-size: 25px;
    text-align: center;
    line-height: auto;
    padding: 10px;
    margin-top: 20px;
    text-decoration: none;
    width: calc(100% - 70px);
    margin-left: 25px;
    margin-right: 25px;
    height: auto;
  }
  .cancel-add-coord-button{
    float: left;
    border-radius: 5px;
    background-color: #046a9d;
    font: inherit;
    color: white;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    line-height: auto;
    margin-top: 10px;
    width: calc(100% - 300px);
    margin-left: 150px;
    margin-right: 150px;
    height: auto;
    padding: 10px;
  }
  
  .add-coord-button:hover{
    background-color: #2EA5E9;
  }
  
  .cancel-add-coord-button:hover{
    background-color: #2EA5E9;
  }
  
  .coord-email-input{
    float: left;
    border-radius: 5px;
    border-width: 1px;
    border-color: black;
    color:black;
    text-indent: 10px;
    font: inherit;
    border-style: solid;
    margin-top: 5px;
    margin-left: calc(25px);
    width: calc(100% - 70px);
    height: auto;
    padding: 10px;
  }
  
  .coord-email-input::placeholder{
    color: #727272;
  }
  
  .add-coord-label-div{
    font: inherit;
    float: left;
    height: auto;
    color: rgb(76, 75, 75);
    line-height: auto;
    padding: 10px;
    margin-left: 20px;
    width: calc(100% - 40px);
    font-size: 18px;
  }
  
  .add-site-layout{
    float: left;
    width: 100%;
    height: 100%;
    background-color: #046a9d;
  }
  
  
  .manage-coords-div{
    @media all and (max-width:900px) {
      width: calc(100% - 40px);
    }
    @media all and (min-width:901px) {
      width: calc(50% - 30px);
    }
    margin-left: 20px;
    margin-top: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: auto;
    float: left;
    background-color:#057cb822;
  }
  
  .manage-sites-div{
    @media all and (max-width:900px) {
      width: calc(100% - 40px);
    }
    @media all and (min-width:901px) {
      width: calc(50% - 30px);
    }
    margin-top: 20px;
    margin-left: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: auto;
    float: left;
    background-color:#057cb822;
  }
  
  .manage-trial-header{
    height: auto;
    font: inherit;
    text-align: center;
    font-size: 20px;
    line-height: auto;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: black;
  }

  .coordinator-div{
    overflow-y: scroll;
    height: fit-content;
    width: calc(100% - 40px);
    margin-left: 20px;
    float: left;
  }

  .add-coord-div{
    float: left;
    height: 60px;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #046a9d;
    color: white;
  }
  
  .add-img{
    height: 100%;
    width: 11%;
    float: left;
  }
  
  .add-text{
    font: inherit;
    height: 100%;
    width: 78%;
    text-align: center;
    color: white;
    float: left;
    line-height: 60px;
    text-decoration: none;
    font-size: 22px;
  }
  

  .add-coord-div:hover {
    background-color: #58BFD8;
  }
  
  