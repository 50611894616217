.main-div{
    float: left;
    width: 100%;
    height: 100vh;
  }
  
  .log-in-div{
    float: left;
    width: 100%;
    background-color:#046a9d;
    height: 100%
  }
  
  .log-in-left-div{
    float: left;
    width:600px;
    background-color: white;
    height: 100%
  }
  
  .log-in-header-div{
    width: 100%;
    height: 40px;
    float: left;
    font-size: 30px;
    color: #057DB8;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .log-in-header-text{
    height: 100%;
    color: inherit;
    line-height: 40px;
    margin-left: 8%;
    font-size: inherit;
    float: left;
  }
  .log-in-header-img{
    margin-top: 5px;
    margin-left: 15px;
    height: 30px;
    float: left;
  }
  
  .log-in-title{
    float: left;
    width:60%;
    margin-top: 100px;
    font-size: 50px;
    margin-left: 8%;
    color: black;
    height: auto;
  }
  
  .log-in-flavour{
    float: left;
    width:100%;
    margin-top: 40px;
    font-size: 20px;
    margin-left: 8%;
    color: black;
    height: 30px;
  }

  .loading-log-in-div{
    float: left;
    width: 85%;
    margin-top: 20px;
    margin-left: 5%;
  
    height: 220px;
  }
  