/*Side Nav Styles*/

.sidenav-full{
    width: 0%;
    height: 100%;
    position: absolute;
    z-index: 3;
    top: 0px;
    bottom: 0px;
    background-color:#0000007e;
    transition: background-color 0.1s;
    float: left;
}

.sidenav-a{
    width: 70px;
    height: 60px;
    x: 0;
    y: 0;
    z-index: 1;
    position: absolute;
    font-family: inherit;
}

.sidenav-small {
    width: 200px;
    left: -200px;
    height: 100%;
    z-index: 2;
    transition: left 0.3s;
    position: absolute;
    font-family: inherit;
    background-color: #057DB8;
  }

  .sidenav {
    width: 200px;
    height: 100vh;
    float: left;
    font-family: inherit;
    background-color: #057DB8;
  }
  
  .side-nav-header-div{
    border-bottom: 1px solid #eaf2fb;
    width: 100%;
    height: 60px;
    float: left;
    color: white;
    font-size: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .side-nav-header-a{
    border-bottom: 1px solid #eaf2fb;
    width: 100%;
    height: 60px;
    float: left;
    color: white;
    font-size: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .side-nav-header-text{
    height: 100%;
    color: inherit;
    line-height: 60px;
    width: 55%;
    margin-left: 5%;
    font-size: inherit;
    float: left;
  }
  
  .side-nav-header-img{
    margin-top: 5px;
    height: 50px;
    width: 30%;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
  }
  .side-nav-header-img2{
    margin-top: 5px;
    height: 50px;
    width: 40px;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
  }

  .side-nav-header-img3{
    margin-top: 5px;
    height: 50px;
    width: 50px;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
  }
  
  .side-nav-menu-header-div{
    margin-top: 20px;
    height: 40px;
    color:rgba(255, 255, 255, 0.833);
    line-height: 50px;
    font-size: 18px;
    float: left;
    margin-left: 8%;
  }
  
  .side-nav-a-div{
    width: 100%;
    float: left;
  }
  
  .tab {
    padding: 8px 8px 8px 14%;
    text-decoration: none;
    font-size: 23px;
    color: #ffffffa3;
    font-family: inherit;
    display: block;
  }
  .tab:hover {
    color:#87dbf0;
  }
  
  .tabOn {
    padding: 8px 8px 8px 14%;
    text-decoration: none;
    font-size: 23px;
    color: white;
    font-family: inherit;
    display: block;
  }