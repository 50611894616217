
.email-input{
  float: left;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  color:black;
  text-indent: 10px;
  font: inherit;
  border-style: solid;
  margin-top: 40px;
  margin-left: 8%;
  width: 70%;
  height: 45px;
}

.password-input{
  float: left;
  font: inherit;
  color:black;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  text-indent: 10px;
  border-style: solid;
  margin-top: 40px;
  margin-left: 8%;
  width: 70%;
  height: 45px;
}

.password-input::placeholder{
  color: #727272;
  
}

.email-input::placeholder{
  color: #727272;
  
}

.forgot-password-a{
  float: left;
  color: #057DB8;
  width: auto;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
  margin-left: 8%;
  height: 30px;
}

.forgot-password-a-2{
  float: left;
  color: #057DB8;
  width: auto;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
  margin-left: 20px;
  height: 15px;
}

.forgot-password-a:hover{
  color:black;
}

.forgot-password-a-2:hover{
  color:black;
}

.log-in-button{
  float: left;
  font: inherit;
  border:  #057DB8;
  color: white;
  background-color: #057DB8;
  width: 70%;
  line-height: 60px;
  font-size: 30px;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  margin-left: 8%;
  height: 60px;
}

.log-in-button:hover{
  background-color:#046a9d;
}

.web-app-div{
  float: left;
  width: 100%;
  height: 100%;
}

.full-div{
  float: left;
  overflow-y: scroll;
  overflow-x: scroll;

  @media all and (min-width: 561px) {
    width: calc(100% - 200px);
  }
  @media all and (max-width: 560px) {
    width: calc(100%);
  }

  @media all and (max-width: 930px) {
    height: calc(100% - 120px);  
  }
  @media all and (min-width: 931px) {
    height: calc(100% - 70px);  
  }
}



/*header styles*/

.top-div{
  width: 100%;
  height: 70px;
  overflow-y: visible;
}

.top-div-mobile{
  width: 100%;
  height: 70px;
  padding-top: 50px;
  overflow-y: visible;
}

.app-header{
  font: inherit;
  height: 70px;
  @media all and (min-width: 561px) {
    width: calc(100% - 200px);
  }
  @media all and (max-width: 560px) {
    width: calc(100%);
  }
  line-height: 70px;
  overflow-x: hidden;
  float: left;  
}

.app-header-1{
  font: inherit;
  font-size: 30px;
  margin-left: 20px;
  height: 100%;
  width: auto;
  color: black;
  float: left;
}

.app-header-2{
  margin-left: 10px;
  font: inherit;
  font-size: 24px;
  height: 100%;
  color: rgb(101, 101, 101);
  width: auto;
  float: left;
}

.trial-label-div{
  font: inherit;
  float: left;
  height: 40px;
  width: calc(100% - 20px);
  color: rgb(76, 75, 75);
  line-height: 40px;
  margin-left: 20px;
  margin-top: 5px;
  font-size: 22px;
}

/* description divs*/

.description-div{
  width: calc(100% - 25px);
  height: 140px;
  padding-left: 20px;
  padding-right: 5px;
  font: inherit;
  text-align: left;
}

.description-div-text1{
  color: black;
  text-wrap: wrap;
  font-size: 24px;
}

.description-div-text2{
  color: rgb(65, 65, 65);
  margin-top: 15px;
  text-wrap: wrap;
  font-size: 17px;
}

.padded-div{
  float: left;
  width: 100%
}

.randomize-patient-div{
  font: inherit;
  float: left;
  height: auto;
  margin-top: 40px;
  padding: 25px;
  @media all and (max-width: 930px) {
    width: 50%;
    margin-left: calc(25% - 25px);  
  }
  @media all and (min-width: 931px) {
    width: 365px;
    margin-left: calc(157.5px);
  }
  background-color: #057DB8;
  color: white;
  line-height: auto;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 25px;
}

.randomize-patient-div-unavailable{
  font: inherit;
  float: left;
  height: auto;
  margin-top: 40px;
  padding: 25px;
  @media all and (max-width: 930px) {
    width: 50%;
    margin-left: calc(25% - 25px);  
  }
  @media all and (min-width: 931px) {
    width: 365px;
    margin-left: calc(157.5px);
  }
  background-color: #a1a1a1;
  color: rgba(255, 254, 254, 0.722);
  line-height: auto;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 25px;
}

.randomize-patient-div-2{
  font: inherit;
  float: left;
  height: auto;
  margin-left: 25%;
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 15px;
  background-color: #057DB8;
  color: white;
  line-height: auto;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 22px;
  
}

.cancel-randomize-patient-div{
  font: inherit;
  float: left;
  height: auto;
  margin-left: 40%;
  width: 20%;
  background-color: #057DB8;
  color: white;
  line-height: auto;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-size: 22px;
  
}

.randomize-patient-div:hover {
  background-color: #58BFD8;
}

.randomize-patient-div-2:hover {
  background-color: #58BFD8;
}

.cancel-randomize-patient-div:hover {
  background-color: #58BFD8;
}

.confirm-patient-div{
  background-color: white;
  border-color: #057DB8;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  position: relative;
  margin-top: 0px;
  margin-left: 20px;
  width: calc(100% - 40px);
  margin-right: 20px;
  float: left;
  @media all and (min-width: 931px) {
    width: 690px;
    margin-right: calc(100% - 720px);
  }
  height: fit-content;
}

.confirm-title-div{
  background-color:#057DB8;
  height: 40px;
  line-height: 40px;
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
  float: left;
  width: 100%;
  text-align: center;
}

.variable-div{
  height: 40px;
  line-height: auto;
  font-size: 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
  width: calc(100% - 20px);
  height: auto;
  text-align: center;
}

.success-div{
  height: auto;
  margin-top: 10px;
  line-height: 50px;
  font-size: 26px;
  float: left;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.transition{
  overflow-y: hidden;
  transition: height 0.5s ease;
  float: left;
  width: 100%;
}

.add-patient-div{
  float: left;
  width: 100%;
  height: 100%;
}

.my-trials-div{
  float: left;
  width: 100%;
  height: 100%;
}

.my-account-div{
  float: left;
  width: 100%;
  height: 100%;
}

.change-password-div{
  float: left;
  width: 100%;
  height: 100%;
}

.recruitment-summary-div{
  float: left;
  width: 100%;
  height: 100%;
}

.manage-trial-div{
  float: left;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}

.white-background{
  float: left;
  width: calc(100% - 4px);
  border: 2px #057DB8 solid;
  border-top: 0;
  background-color: white;
  height: fit-content;
}

.my-account-list-div{
  height: calc(100% - 20px);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color:#057cb822;
  margin-left: 20px;
  margin-top: 10px;
  width: 40%;
  float: left;
}

.my-account-info-div{
  height: calc(100% - 20px);
  overflow-y: scroll;
  border-left: white 1px solid;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color:#057cb822;
  margin-top: 10px;
  width: calc(60% - 40px);
  float: left;
}

.my-account-title{
  width: 100%;
  height: 50px;
  font-size: 30px;
  text-align: center;
  line-height: 50px;
  color: white;
  border-top-left-radius: 5px;
  background-color:#046a9d
}

.my-account-scroll{
  width: 100%;
  overflow-y: scroll;
  height: calc(100% - 50px);
}

.patient-div{
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: black;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  float: left;
  display: flex;
  justify-content: center;
}

.patient-div-open{
  background-color:#2EA5E9;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  color: white;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  float: left;
  display: flex;
  justify-content: center;
}

.patient-text-div{
  height: 100%;
  width: 68%;
  display: flex;
  margin-left: 2%;
  flex-direction: column;
  justify-content: center;
  color: inherit;
  float: left;  
}

.patient-top-div{
  width: 100%;
  font-size: 18px;
  height: fit-content;
  align-self: center;

}

.patient-middle-div{
  width: 100%;
  font-size: 22px;
  text-wrap: wrap;
  height: auto;
  align-self: center;

}

.patient-img{
  float: left;
  height: auto;
  padding: 2%;
  margin-top: auto;
  margin-bottom: auto;
  width: 15%;
}

.patient-img-2{
  float: left;
  height: auto;
  padding: 2%;
  margin-top: auto;
  margin-bottom: auto;
  width: 10%;
}

.patient-bottom-div{
  width: 100%;
  font-size: 16px;  
  align-self: center;
  height: fit-content;
  
}

.patient-label-div{
  height: auto;
  width: 98%;
  margin-left: 2%;
  color: #414141;
  font-size: 20px;
  float: left;
  line-height: auto;
  margin-top: 10px;
}
.patient-info-div{
  height: auto;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  color: black;
  font-size: 22px;
  float: left;
  line-height: 30px;
}


.my-trials-list-div{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color:#057cb822;
  margin-left: 20px;
  float: left;
  width: calc(50% - 20px);
  height: calc(100% - 150px);
}

.my-trial-info-div{
  overflow-y: scroll;
  border-left: white 1px solid;
  border-bottom-right-radius: 5px;
  background-color:#057cb822;
  float: left;
  width: calc(50% - 20px);
  height: calc(100% - 150px);
}

.my-trials-title{
  width: 100%;
  height: 50px;
  font-size: 30px;
  border-top-left-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: white;
  background-color:#046a9d
}

.my-trials-title-2{
  width: 100%;
  height: 50px;
  font-size: 30px;
  border-top-right-radius: 5px;
  text-align: center;
  line-height: 50px;
  color: white;
  background-color:#046a9d
}

.my-trials-scroll{
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.my-trials-name{
  float: left;
  width: calc(100% - 20px);
  font-size: 22px;
  height: fit-content;
  margin: 10px;
}

.my-trials-description{
  float: left;
  width: calc(100% - 20px);
  font-size: 20px;
  height: fit-content;
  margin: 10px;
}

.my-trials-add-patient{
  background-color: #046a9d;
  width: auto;
  margin: 10px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
  color: white;
  font-size: 22px;
  border-radius: 5px;
  text-align: center;
  line-height: auto;
  height: auto;
  text-decoration: none;
  float: left;
}

.my-trials-add-patient:hover{
  background-color: #2EA5E9;
}

.my-trials-patients-recruited{
  float: left;
  width: calc(100% - 20px);
  font-size: 20px;
  height: fit-content;
  margin: 10px;
}

.my-trials-sites-title{
  float: left;
  width: calc(100% - 20px);
  font-size: 18px;
  color: black;
  height: fit-content;
  margin: 10px;

}

.my-trials-site{
  float: left;
  width: calc(100% - 20px);
  color: #414141;
  font-size: 18px;
  height: fit-content;
  margin: 10px;
}

.trial-div{
  height: auto;
  width: 100%;
  color: black;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  float: left;
  display: flex;
  justify-content: center;
}

.trial-div-open{
  height: auto;
  width: 100%;
  color: white;
  background-color:#2EA5E9;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-align: left;
  float: left;
  display: flex;
  justify-content: center;
}

.trial-text-div{
  height: calc(100% - 20px);
  width: calc(75% - 20px);
  display: flex;
  margin: 10px;
  flex-direction: column;
  justify-content: center;
  color: inherit;
  float: left;  
}

.trial-top-div{
  width: 100%;
  font-size: 26px;
  height: fit-content;
  align-self: center;

}

.trial-img{
  float: left;
  height: auto;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  width: calc(15% - 10px);
}

.trial-img-2{
  float: left;
  height: auto;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
  width: calc(15% - 5px);
}

.trial-bottom-div{
  width: 100%;
  font-size: 14px;  
  align-self: center;
  height: fit-content;
  
}



.hamburger{
  font-weight: bold;
  font-family: "Sen-Regular";
  text-align: center;
  height:60px;
  display: inline;
  line-height: 60px;
  font-size: 30px;
  color: white;
  background-size: 70%;
  width: 80px;
  top: 0;
  float: left;
  background-color:  #58BFD8;
  vertical-align: middle;
  text-indent: 5px;
}



@font-face {
  font-family: 'Sen-Regular';
  src: local('Sen-Regular'), url(./fonts/Sen-Regular.ttf) format('truetype');
}

#main {
  transition: margin-left .5s;
}

.mobile-div {
  @media all and (min-width: 561px){
    display: none;
  }
  width: 100%;
  z-index: 0;
}

.desktop-div1 {
  @media all and ((max-width: 560px ) or (min-width: 931px))  {
    display: none;
  }
  width: 100%;
  z-index: 1;
}

.desktop-div2 {
  @media all and (max-width: 930px) {
    display: none;
  }
  width: 100%;
  z-index: 2;
}

select {
  background-color: #2EA5E9;
  background-image: url("imgs/arrowdown.svg");
  background-position: calc(100% - 10px);
  background-size:
    40px 40px;
  background-repeat: no-repeat;
  border-radius: 4px;
  border-color: #2EA5E9;
  font: inherit;
  font-size: 20px;
  height:auto;
  text-wrap:pretty;
  width: calc(100% - 40px);
  margin-right: 20px;
  @media all and (min-width: 931px) {
    width: 690px;
    margin-right: calc(100% - 720px);
  }
  line-height:normal;
  text-align: center;
  outline: none;
  margin-left: 20px;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  float: left;
  appearance: none;
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

select:hover {
  background-color: #057DB8;
}

.select-label {
  /* styling */
  background-color:#057DB8;
  color: white;
  border-radius: 5px;
  border-width: 1px;
  border-color:black;
  border-style: solid;
  font: inherit;
  font-size: 25px;
  line-height: 60px;  
  text-align: center;
  margin-top: 10px;
  height: 60px;
  width: 10%;
  margin-left: calc(5% - 2px);
  float: left;
}

.full-width-div{
  width: 100%;
  height: auto;
  float: left;
}

.coordinator-a{
  margin-bottom: 1px;
  height: 80px;
  width: 100%;
  color: white;
  background-color: #057DB8;
  float: left;
}

.coordinator-a-closed{
  margin-bottom: 1px;
  height: 80px;
  margin-left: 3px;
  color: white;
  width: calc(100% - 6px);
  background-color:#2EA5E9;
  float: left;
}

.coordinator-a-closed:hover{
  background-color: #057DB8;
}


/*my account styles*/

.my-account-a{
  font: inherit;
  float: left;
  color:#046a9d;
  height: 54px;
  width: 100%;
}

.my-account-a:hover{
  background-color:#2EA5E9;
  border-radius: 5px;
}

.my-account-a-open{
  font: inherit;
  float: left;
  height: 54px;
  width: 100%;
}

.my-account-a-open:hover{
  background-color:#2EA5E9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.my-account-img1{
  width: 35px;
  margin-left: 7px;
  margin-right: 7px;
  height: 100%;
  float: left;
}

.my-account-info{
  width: calc(100% - 98px);
  float: left;
  height: 100%;
  display: grid;
  place-content: center;
}

.my-account-img2{
  width: 35px;
  margin-left: 7px;
  margin-right: 7px;
  height: 100%;
  float: left;
}

.my-account-comp1 {
  /* styling */
  color: white;
  font: inherit;
  text-decoration: none;
  font-size: 18px;
  height:fit-content;
  width: 100%;
  float: left;
  text-align:left;
  display: block;
}

.my-account-comp2 {
  /* styling */
  color: white;
  font: inherit;
  font-size: 14px;
  display: block;
	text-align:left;
  height:fit-content;
  width: 100%;
  float: left;
}

.my-account-dropdown-comp{
  position: absolute;
  height: 55px;
  top: 2.5px;
  left:calc(100% - 290px);
  width: 280px;
  z-index: 2;
  transition: height 0.3s, background-color 0.1s;
  background-color: #057DB8;
  border-radius: 5px;
}


.my-account-a:hover{
  background-color:#2EA5E9;
}

.manage-account-a{
  float: left;
  color: white;
  border-top-color: white;
  background-color:#057DB8;
  border-top-width: 1px;
  border-top-style:solid;
  width: 100%;
  height: 54px;

}

.manage-account-a:hover{
  background-color:#2EA5E9;
}

.sign-out-a{
  float: left;
  color: white;
  background-color:#057DB8;
  border-top-color: white;
  border-top-width: 1px;
  border-top-style:solid;
  width: 100%;
  height: 54px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sign-out-a:hover{
  background-color:#2EA5E9;
}

.sign-out-text{
  float: left;
  color: white;
  width: calc(100% - 98px);
  line-height: 54px;
  text-align: center;
  height: 100%;
}

.account-img1{
  width: 10%;
  height: 100%;

  float: left;
  margin-left: 2%;
}

.account-info{
  width: 78%;
  float: left;
  height: 100%;
	align-items: center;
  place-content: center;
}

.account-img2{
  width: 8%;
  margin-right: 2%;

  height: 100%;
  float: left;
}

.coordinator-comp1 {
  /* styling */
  color: inherit;
  font: inherit;
  text-decoration: none;
  font-size: 19px;
  text-align: center;
  height:fit-content;
  text-overflow: ellipsis;
  text-wrap:wrap;
  width: 100%;

  float: left;

	
}

.coordinator-comp2 {
  /* styling */
  color: inherit;
  font: inherit;
  text-decoration: none;
  font-size: 15px;
  overflow-x: hidden;
  text-align: center;
  height:fit-content;
  text-overflow: ellipsis;
  width: 100%;
  float: left;
}

.assigned-sites-div{
  
  border-color: black;
  border-width: 1px;
  border-style: solid;  
  
  margin-top: 10px;
  width: 53%;
  margin-left: 2%;
  float: left;
  min-height: 400px;

}

.site-a{
  left: 0;
  top: 0;
  height: 45px;
  width: 100%;
  float: left;
}

.site-a:hover{
  background-color:#eaf2fb;
}

.site-img{
  width: 10%;
  margin-top: 7.5px;
  padding-right: 1%;
  height: 30px;
  float: left;
}

.site-div{
  width: 89%;
  float: left;
  font: inherit;
  height: 100%;
  margin: 0;
  font-size: 17px;
  display: grid;
  align-items: center;
  text-align: left;
}

.remove-and-save-buttons{
  width: 100%;
  float: left;
  justify-content: center;
  background-color: #057DB8;
  height: auto;
}

.remove-coord-a{
  height: 50px;
  width: 250px;
  color: white;
  background-color:#2EA5E9;
  border-radius: 3px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: calc(50% - 125px);
  float: left;
}

.remove-coord-a:hover{
  background-color:#58BFD8;
}

.remove-coord-div{
  width:fit-content;
  float: left;
  font: inherit;
  height: 100%;
  line-height: auto;
  font-size: 17px;
  margin-left: 10px;
  color: white;
  display: grid;
  align-items: center;
  text-align: center;
}

.remove-coord-img{
  width: 35px;
  padding: 10px;
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}

.save-changes-a{
  height: 50px;
  width: 250px;
  float: left;
  color: white;
  background-color:#2EA5E9;
  border-radius: 3px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: calc(50% - 125px);
}

.save-changes-a:hover{
  background-color:#58BFD8;
}

.save-changes-div{
  width:fit-content;
  float: left;
  font: inherit;
  height: 100%;
  line-height: auto;
  font-size: 17px;
  margin-left: 10px;
  color: white;
  display: grid;
  align-items: center;
  text-align: center;
}

.save-changes-img{
  width: 35px;
  padding: 10px;
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}

.loading-img{
  width: 60%;
  margin-left:20%;
  margin-top: auto;
  display: block;
  height: auto;
  float: left;
}
.loading-text{
  width: 100%;
  height: fit-content;
  text-align: center;
  font-size: 23px;
  display: block;
  float: left;
}

.loading-div{
  float: left;
  width: 100%;
  height: 100%;
  border-color: #057DB8;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  text-align: center;
  display: grid;
  place-content: center;
}

.loading-parent-manage-trials{
  float: left;
  margin-top: 40px;
  width: 200px;
  height: 200px;
  align-self: center;
}

.loading-parent-add-patient{
  float: left;
  margin-top: 20px;
  width: 250px;
  height: 150px;
  margin-left: calc(50% - 125px);
  align-self: center;
}


.checkbox-div{
  height: 30px;
  width: 100%;
  border-top: 1px white solid;
  color: white;
  background-color: #057DB8;
  font-size: 17px;
  text-align: center;
  line-height: 30px;
  float: left;
}

.plotly-graph{
  width: fit-content;
  height: fit-content;
}

body {
  background-color: white;
  font-weight: bold;
  font-family: "Sen-Regular";
  /*@media (min-width: 560px) {
    min-width:1000px;
    max-width:1000px;
  }
  @media (max-width: 560px) {
    min-width:750px;
    max-width:750px;
  }*/
}

.old-password-input{
  float: left;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  color:black;
  text-indent: 10px;
  font: inherit;
  border-style: solid;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: calc(100% - 370px);
  width: 350px;
  height: 45px;
}

.new-password-input{
  float: left;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  color:black;
  text-indent: 10px;
  font: inherit;
  border-style: solid;
  margin-top: 5px;
  margin-left: 20px;
  margin-right: calc(100% - 370px);
  width: 350px;
  height: 45px;
}

.new-password-2-input{
  float: left;
  border-radius: 5px;
  border-width: 1px;
  border-color: black;
  color:black;
  text-indent: 10px;
  font: inherit;
  border-style: solid;
  margin-right: calc(100% - 370px);
  margin-top: 5px;
  margin-left: 20px;
  width: 350px;
  height: 45px;
}

.old-password-input::placeholder{
  color: #727272;
}

.new-password-input::placeholder{
  color: #727272;
  
}

.new-password-2-input::placeholder{
  color: #727272;
}

.password-label-div{
  font: inherit;
  float: left;
  height: 40px;
  color: rgb(76, 75, 75);
  line-height: 40px;
  margin-left: 20px;
  margin-top: 15px;
  width: calc(100% - 20px);
  font-size: 20px;
}

.password-criteria-div{
  font: inherit;
  float: left;
  height: 25px;
  color: rgb(76, 75, 75);
  line-height: 25px;
  margin-left: 30px;
  margin-top: 18px;
  margin-bottom: 18px;
  width: 450px;
  font-size: 15px;
}

.password-criteria-dot-red{
  font: inherit;
  float: left;
  height: 25px;
  color: #EB3434;
  line-height: 25px;
  margin-left: 5px;
  margin-right: calc(100% - 505px);
  margin-top: 18px;
  margin-bottom: 18px;
  width: 20px;
  font-size: 70px;
}

.password-criteria-dot-green{
  font: inherit;
  float: left;
  height: 25px;
  color: #52EB34;
  margin-left: 5px;
  line-height: 25px;
  margin-top: 18px;
  margin-bottom: 18px;
  margin-right: calc(100% - 505px);
  width: 20px;
  font-size: 70px;
}


.change-password-button{
  float: left;
  font: inherit;
  border:  #057DB8;
  color: white;
  background-color: #057DB8;
  width: 354px;
  line-height: 60px;
  font-size: 23px;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  margin-top: 30px;
  margin-left: 20px;
  height: 60px;
}

